import moment from "moment";
import { Loader } from "rsuite";
import { Tooltip } from "react-tooltip";
import { MdInfoOutline } from "react-icons/md";

const InfoCard = ({ patient, isAdmin, handleSync, isLoadingSync }) => {
  const {
    firstName,
    lastName,
    birthDate,
    linePhone,
    mobilePhone,
    email,
    socialSecurityNumber,
    location,
    syncInProgress,
    lastSync,
    prescribers,
    salesManager,
    nursingConsultant,
    regionalManager,
    directors,
  } = patient || {};

  const InfoRow = ({ label, value, className, valueClassName }) =>
    value ? (
      <div className={className}>
        <span className="font-semibold">{label} :</span>{" "}
        <div className={valueClassName}>{value}</div>
      </div>
    ) : null;

  const InfoRowWithTooltip = ({ anchorId, value, tooltipContent, isLast }) => (
    <span className="flex flex-row items-center space-x-1 w-fit">
      <span className="w-fit">{value}</span>
      <MdInfoOutline
        id={anchorId}
        className="w-fit cursor-pointer"
        color="var(--dark)"
      />
      <Tooltip
        anchorSelect={`#${anchorId}`}
        place="top"
        style={{
          backgroundColor: "white",
          padding: 0,
          margin: 0,
        }}
        classNameArrow="bg-white border border-b-gray-200 border-r-gray-200 border-t-white border-l-white"
        clickable
      >
        {tooltipContent}
      </Tooltip>
      {!isLast && <div> - </div>}
    </span>
  );

  const calculateAge = (birthDate) => moment().diff(moment(birthDate), "years");

  return (
    <div>
      <div className="mb-3 text-2xl font-semibold text-primary">
        {lastName} {firstName}
      </div>
      <div className="flex flex-col gap-1">
        {birthDate && (
          <>
            <div>{calculateAge(birthDate)} ans</div>
            <div>
              Né(e) le <span>{moment(birthDate).format("DD/MM/YYYY")}</span>
            </div>
          </>
        )}
        <InfoRow label="Tel fixe" value={linePhone} />
        <InfoRow label="Tel mobile" value={mobilePhone} />
        {email &&
          !email.includes("@isisdiabete.fr") &&
          !email.includes("ISISDIABETE.FR") && (
            <InfoRow label="Email" value={email} />
          )}
        <InfoRow label="N° sécurité sociale" value={socialSecurityNumber} />
        {location && (
          <span>
            <span className="font-semibold">Adresse :</span>{" "}
            {location.streetNumber} {location.street}, {location.zipCode}{" "}
            {location.city}
          </span>
        )}
      </div>
      {isAdmin && (
        <div className="flex flex-col gap-1">
          <button
            className={`px-3 py-1 mt-5 font-semibold border flex justify-center items-center gap-2 rounded w-fit border-dark text-dark hover:bg-dark hover:text-white ${
              isLoadingSync || syncInProgress ? "opacity-70 cursor-default" : ""
            }`}
            onClick={async () => await handleSync(patient._id)}
            disabled={isLoadingSync || syncInProgress}
          >
            Synchroniser le patient{" "}
            {isLoadingSync || syncInProgress ? <Loader /> : null}
          </button>
          <span className="text-xs">
            {syncInProgress
              ? "Synchronisation en cours..."
              : lastSync
              ? `Dernière maj manuelle : ${moment(lastSync).format(
                  "DD/MM/YYYY HH:mm"
                )}`
              : ""}
          </span>
        </div>
      )}
      <hr className="my-5" />
      <div className="flex flex-col gap-1">
        {prescribers && prescribers.length > 0 && (
          <InfoRow
            label="Prescripteurs"
            value={prescribers
              .map((prescriber) => prescriber.user.fullName)
              .join(" - ")}
          />
        )}
        <InfoRow label="Responsable commercial" value={salesManager} />
        {nursingConsultant && nursingConsultant.length > 0 && (
          <InfoRow
            label="Infirmiers"
            valueClassName="flex flex-row space-x-1"
            data-event="click focus"
            value={nursingConsultant.map((nurse, index) => (
              <InfoRowWithTooltip
                key={`nurse_${index}`}
                anchorId={`nurse_${index}_tooltip`}
                value={nurse.name}
                tooltipContent={
                  <div className="bg-white p-2 border border-gray-200 text-gray-600">
                    <span>
                      <span className="font-semibold text-dark">Email:</span>{" "}
                      <span>{nurse.email}</span>
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold text-dark">
                        Téléphone:
                      </span>{" "}
                      <span>{nurse.phoneNumber}</span>
                    </span>
                  </div>
                }
                isLast={index + 1 === nursingConsultant.length}
              />
            ))}
          />
        )}
        {regionalManager && regionalManager.length > 0 && (
          <InfoRow
            label="Responsable de région"
            valueClassName="flex flex-row space-x-1"
            value={regionalManager.map((manager, index) => (
              <InfoRowWithTooltip
                key={`manager_${index}`}
                anchorId={`manager_${index}_tooltip`}
                value={manager.name}
                tooltipContent={
                  <div className="bg-white p-2 border border-gray-200 text-gray-600">
                    <span>
                      <span className="font-semibold text-dark">Email:</span>{" "}
                      <span>{manager.email}</span>
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold text-dark">
                        Téléphone:
                      </span>{" "}
                      <span>{manager.phoneNumber}</span>
                    </span>
                  </div>
                }
                isLast={index + 1 === regionalManager.length}
              />
            ))}
          />
        )}
        {isAdmin && directors && directors.length > 0 && (
          <InfoRow
            label="Directeur"
            valueClassName="flex flex-row space-x-1"
            value={directors.map((director, index) => (
              <InfoRowWithTooltip
                key={`director_${index}`}
                anchorId={`director_${index}_tooltip`}
                value={director.name}
                tooltipContent={
                  <div className="bg-white p-2 border border-gray-200 text-gray-600">
                    <span>
                      <span className="font-semibold text-dark">Email:</span>{" "}
                      <span>{director.email}</span>
                    </span>
                    <br />
                    <span>
                      <span className="font-semibold text-dark">
                        Téléphone:
                      </span>{" "}
                      <span>{director.phoneNumber}</span>
                    </span>
                  </div>
                }
                isLast={index + 1 === directors.length}
              />
            ))}
          />
        )}
      </div>
    </div>
  );
};

export default InfoCard;
