import { saveAs } from "file-saver";

import API from "../utils/api";

class FileService {
  async download(type, typeId, docId) {
    try {
      const response = await API.get(`/${type}/${typeId}/document/${docId}`, {
        responseType: "blob",
      });
      if (response.data) {
        const fileName = response.headers["content-disposition"].split('"')[1];
        const fileType = response.headers["content-type"];
        saveAs(new Blob([response.data], { type: fileType }), fileName);
      }
    } catch (error) {
      return error.response.data.message;
    }
  }
  async downloadOrdo(prescriptionId) {
    try {
      const response = await API.get(
        `/prescriptions/${prescriptionId}/document`,
        {
          responseType: "blob",
        }
      );
      if (response.data) {
        const fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/"/g, "");
        const fileType = response.headers["content-type"];
        saveAs(new Blob([response.data], { type: fileType }), fileName);
      }
    } catch (error) {
      return error.response.data.message;
    }
  }

  async downloadFile(result) {
    try {
      if (result.data) {
        const fileName = result.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/"/g, "");
        const fileType = result.headers["content-type"];

        // Si vous utilisez axios, assurez-vous d'avoir configuré responseType: 'arraybuffer'
        const blob = new Blob([new Uint8Array(result.data)], {
          type: fileType,
        });
        saveAs(blob, fileName);
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement:", error);
      return error;
    }
  }
}

export default FileService;
