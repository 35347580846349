import { useEffect, useState, useCallback } from "react";
import { useModal } from "../../../context/modal-context";
import { getLabelPrescription } from "../../../utils/sign";
import Yousign from "../../../classes/Yousign";
import RenewalForm from "../RenewalForm";

const ModalRenewalPrescription = ({
  handleSign,
  prescription,
  prescriber,
  prescribers,
  handleSignFinish,
  printMode,
}) => {
  const [signatureLink, setSignatureLink] = useState(null);
  const { setModalFullScreen, setModalLabel } = useModal();

  const handleSignatureLinkUpdate = useCallback(
    (link) => {
      setModalLabel(null);
      if (link) {
        const yousign = new Yousign({
          signatureLink: link,
          iframeContainerId: "iframe-container",
          isSandbox: true,
          iFrameAttributes: {
            referrerPolicy: "origin-when-cross-origin",
          },
        });
        yousign.onStarted((data) => {
          console.log("Signer has opened the signature");
          console.log(data);
        });

        yousign.onSuccess((data) => {
          console.log("Signer has successfully signed the document");
          console.log(data);
        });

        yousign.onError((data) => {
          console.log("Signer encountered an error when signing");
          console.log(data);
        });

        yousign.onPing((data) => {
          console.log("Ping - The signature request is loaded");
          console.log(data);
        });

        yousign.onDeclined((data) => {
          console.log("DeP0+r\P0+r\P1+r6B62=7F\P0+r\P1+r6B44=1B5B337E\P1+r6B68=1B4F48\P1+r4037=1B4F46\P1+r6B50=1B5B357E\P1+r6B4E=1B5B367E\clined - The signer declined the signature");
          console.log(data);
        });

        yousign.onSignatureDone((data) => {
          console.log("Signature Done - The signature request is completed");
          console.log(data);
        });
        setModalFullScreen(true);
      } else {
        setModalFullScreen(false);
      }
    },
    [setModalFullScreen, setModalLabel]
  );

  useEffect(() => {
    handleSignatureLinkUpdate(signatureLink);
  }, [signatureLink, handleSignatureLinkUpdate]);

  return (
    <div className="flex flex-col justify-center w-full h-full p-3">
      <span className="font-bold uppercase text-start">
        renouvellement {getLabelPrescription(prescription.contract.plan.code)}
      </span>
      <div
        className={`flex flex-col gap-5 ${
          signatureLink ? "w-full h-full" : ""
        }`}
      >
        {signatureLink ? (
          <div className="flex flex-col w-full h-full gap-5 mt-3">
            <div id="iframe-container" className="w-full h-full" />
            <button
              type="submit"
              className="self-end px-3 py-1 font-semibold border rounded w-fit border-dark text-dark hover:bg-dark hover:text-white"
              onClick={handleSignFinish}
            >
              Fermer
            </button>
          </div>
        ) : (
          <RenewalForm
            prescription={prescription}
            prescriber={prescriber}
            prescribers={prescribers}
            onSubmit={handleSign}
            setSignatureLink={setSignatureLink}
            printMode={printMode}
          />
        )}
      </div>
    </div>
  );
};

export default ModalRenewalPrescription;

